/*!
 * 
 *  Custom Scripts
 * 
 */

//===================================================================		
//  Push the footer to bottom of page
//===================================================================

/*jQuery(document).ready(function( $ ) {
   var docHeight = $(window).height();
   var footerHeight = $('#footer').outerHeight();
   var footerTop = $('#footer').position().top + footerHeight;

   if (footerTop < docHeight) {
    $('#footer').css('margin-top', 10+ (docHeight - footerTop) + 'px');
   }
  });*/

//===================================================================		
// END of Push the footer to bottom of page
//===================================================================	


/*---------------------------------------------------------------------------------- 
/*   START :- Cookie Bar
--------------------------------------------------------------------------------- */
jQuery(document).ready(function( $ ) {
jQuery.cookieBar({
message:'We use cookies to give you the best online experience. By using this website you agree with our cookie policy.',
fixed: true,
acceptOnScroll: 300,
bottom: true,
effect: 'slide',
zindex: '5001',
policyButton: false,
policyText: 'Read more',
//policyURL: ''
policyURL: ''

});
});

/*---------------------------------------------------------------------------------- 
 * END :- Cookie Bar
--------------------------------------------------------------------------------- */
	


/*---------------------------------------------------------------------------------- 
/*   START :-  SCROLL To Top
--------------------------------------------------------------------------------- */

jQuery(document).ready(function( $ ) {
	
	//Check to see if the window is top if not then display button
	jQuery(window).scroll(function(){
		if (jQuery(this).scrollTop() >= 1200) {		// If page is scrolled more than 50px
			jQuery('.scrollToTop').fadeIn(800);		// Fade in the arrow
		} else {
			jQuery('.scrollToTop').fadeOut(800);		// Else fade out the arrow
		}
	});
	
	//Click event to scroll to top
	jQuery('.scrollToTop').click(function(){					// When arrow is clicked
		jQuery('html, body').animate({scrollTop : 0},800); 	// Scroll to top of body
		return false;
	});
	
});


/*---------------------------------------------------------------------------------- 
 * END :-  SCROLL To Top
--------------------------------------------------------------------------------- */



/*---------------------------------------------------------------------------------- 
/*   START :- Form Options Show / Hide
--------------------------------------------------------------------------------- */

jQuery(document).ready(function() {
	
	jQuery("[name='qf_contactMethod']").change(function(){ 
    //alert($(this).val());
    if(jQuery(this).val() == "by Phone" )
    {
        jQuery('#callTime').slideDown();
    }
    else
    {
		// dide boxes
         jQuery('#callTime').slideUp();
		  jQuery('#timebottom').slideUp();
		  // reset values
		  jQuery("#qf_specifictime").val("");
		  jQuery("#qf_mytime").val(jQuery("#qf_mytime option:first").val());

    }
     });
	
	
  
	jQuery("[name='qf_mytime']").change(function(){ 
    //alert($(this).val());
    if(jQuery(this).val() == "othertime" )
    {
        jQuery('#timebottom').slideDown();
    }
    else
    {
         jQuery('#timebottom').slideUp();
    }
     });
	 
});


/*---------------------------------------------------------------------------------- 
 * END :- Form Options Show / Hide
--------------------------------------------------------------------------------- */

	
/*---------------------------------------------------------------------------------- 
/*   START :- Modal From Quad Menu Nav
--------------------------------------------------------------------------------- */

jQuery(document).ready(function(){
  jQuery('.quadmenu-item-object-button').click(function(){
    //alert("The paragraph was clicked.");
	 event.preventDefault();
	UIkit.modal('#newsletter').show();
	
  });
});


/*---------------------------------------------------------------------------------- 
 * END :- Modal From Quad Menu Nav
--------------------------------------------------------------------------------- */

